import React from "react";
import styled from "styled-components";
import { CalendarDays, ChevronsUp } from "lucide-react";
import ManualSyncSvg from "assets/icons/manual-sync.svg";
import DocumentationSection from "../../components/docs/DocumentationSection";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { RouterLocation } from "../../types/types";
import { Text, Badge, Card } from "@merge-api/merge-javascript-shared";

interface Props {
  location: RouterLocation;
}

const SyncFrequencyBadge = styled.div<{ $color: string }>`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${({ $color }) => $color};
  gap: 8px;
`;

const SectionDivider = styled.div`
  padding-top: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--gray10);
  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
`;

export const SyncFrequencyOverviewTable = () => (
  <Card className="w-full grid grid-cols-2 lg:grid-cols-4">
    <div className="flex flex-col col-span-2 lg:col-span-1 border-r-[0px] lg:border-r-[1px] border-gray-10">
      <div className="flex flex-row justify-between items-center border-b-[1px] border-gray-10 px-4 py-3">
        <Text variant="h5" className="text-orange-60 whitespace-nowrap mb-0">
          <ChevronsUp size={16} className="mr-2" />
          Highest
        </Text>
        <Badge color="teal">Automatic</Badge>
      </div>
      <div className="flex justify-start border-gray-10 px-4 py-3 h-full">
        Data syncs at the highest frequency supported by Merge.
      </div>
    </div>
    <div className="flex flex-col col-span-2 lg:col-span-1 border-r-[0px] lg:border-r-[1px] border-gray-10 border-t-[1px] lg:border-t-[0px]">
      <div className="flex flex-row justify-between items-center border-b-[1px] border-gray-10 px-4 py-3">
        <Text variant="h5" className="text-yellow-60 whitespace-nowrap mb-0">
          <CalendarDays size={16} className="mr-2" />
          Daily
        </Text>
        <Badge color="teal">Automatic</Badge>
      </div>
      <div className="flex justify-start border-gray-10 px-4 py-3 h-full">
        Data syncs every 24 hours.
      </div>
    </div>

    <div className="flex flex-col col-span-2 border-gray-10 border-t-[1px] lg:border-t-[0px]">
      <div className="flex flex-row">
        <div className="flex flex-row justify-between items-center border-b-[1px] border-r-[1px] border-gray-10 px-4 py-3 w-6/12">
          <Text variant="h5" className="text-gray-60 whitespace-nowrap mb-0">
            <ManualSyncSvg className="mr-2" />
            Monthly
          </Text>
          <Badge>Manual</Badge>
        </div>
        <div className="flex flex-row justify-between items-center border-b-[1px] border-gray-10 px-4 py-3 w-6/12">
          <Text variant="h5" className="text-gray-60 whitespace-nowrap mb-0">
            <ManualSyncSvg className="mr-2" />
            Quarterly
          </Text>
          <Badge>Manual</Badge>
        </div>
      </div>
      <div className="flex justify-start  border-gray-10 px-4 py-3 h-full">
        Control when you sync data either in your Merge dashboard or via API. Resyncs are limited
        based on your allocated amount of sync credits.
      </div>
    </div>
  </Card>
);

function DocumentationSyncFrequencyPage({ location }: Props) {
  const title = "Sync frequency";
  const description =
    "Learn about the different sync frequencies for data from third parties to Merge.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <p>
          Sync frequency is the rate at which Merge initiates requests to fetch data from
          third-parties. Actual time to complete a sync can vary depending on the third-party.
        </p>
        <p>
          You can view your sync frequency in the{" "}
          <a href="https://app.merge.dev/billing">Billing</a> page of your Merge dashboard.{" "}
          <a href="mailto: support@merge.dev">Contact us</a> for more information.
        </p>

        <SyncFrequencyOverviewTable />
      </DocumentationSection>

      <SectionDivider />

      <DocumentationSection title="Sync credits">
        <p>
          Customers who select Manual sync plans (<b>Monthly</b> or <b>Quarterly</b>) will be
          allocated sync credits that apply per Linked Account, including bonus sync credits for
          mistakes and changes.
        </p>
        <p>
          A sync credit applies when you resync data for a Linked Account in the Merge dashboard or
          via API. The initial sync for a Linked Account does not use a sync credit.
        </p>
        <p>
          Sync credits renew each year, starting on the day that each Linked Account begins syncing.
        </p>
      </DocumentationSection>

      <br />

      <CrossGuideLinks location={location} style={{ marginTop: "12px " }} />
    </StaticPageContainer>
  );
}

export default DocumentationSyncFrequencyPage;
